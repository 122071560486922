import React, { useState, useEffect } from 'react'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isAndroid,
    isIOS,
    isMobile
} from "react-device-detect";
import Nav from './Navbar'
import Map from './map'

import './html/css/style.css'
import phone1 from './images/PHONE3.png'
import phone2 from './images/PHONE4.png'
import google from './images/google.png'
import apple from './images/Apple.png'

const Home = (props) => {
    return (
        <div>
            <Nav />

            <main id="home" class="masthead masked" style={{ marginTop: 50 }}>
                <div class="opener rel-1" >
                    <div class="container" >
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <h1 class="text-white mb-3">Welcome to OrderCash!</h1>
                                <p class="lead-text mb-4">Quick cash delivery to your location. <br /></p>
                                <div class="lead-controls">
                                    <a href="#download" class="btn-lead btn mr-3 text-light">Download now</a>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-5 mb-lg-0">
                                <img alt="" class="mw-100 d-block mx-auto" src={phone1} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <div class="content">

                <section class="text-center section" id='about'>
                    <div class="container p-4">
                        <div style={{ marginTop: -50, marginBottom: 50 }}>
                            <p style={{ fontSize: 36, fontWeight: 'Regular', color: 'black' }}>What is OrderCash about?</p>
                            <p class="mb-4" style={{ color: 'grey' }}>ATMs are becoming relics of a digital past while humans with cash and POS machines (agents) are the  <br />way to go for many in remote and upscale locations in Africa.

                            <br />OrderCash brings that layer of extra convenience for cash-strapped customers who <br />want their cash delivered to them anywhere they are.

                            <br />
                            </p>
                            <p style={{ color: '#017fff', fontWeight:'bold' }}>OrderCash only takes orders for the following locations in Lagos:</p>
                        </div>

                        <div class="row">
                            <div class="col-md-6 col-lg-4 mb-5 mb-lg-0 wow fadeInUp">
                                <i class="fa fa-4x fa-map-marker"></i>
                                <h5 class="mt-3" style={{ color: '#017fff' }}>Lekki</h5>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> */}
                            </div>
                            <div class="col-md-6 col-lg-4 mb-5 mb-lg-0 wow fadeInUp" data-wow-delay="0.3s">
                                <i class="fa fa-4x fa-map-marker"></i>
                                <h5 class="mt-3" style={{ color: '#017fff' }}>Victoria Island</h5>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> */}
                            </div>
                            <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.6s">
                                <i class="fa fa-4x fa-map-marker"></i>
                                <h5 class="mt-3" style={{ color: '#017fff' }}>Ikoyi</h5>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> */}
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Features  --> */}

                <section >
                    <Map />
                </section>

                <section class='section' style={{backgroundColor:'#f5f5f5'}} id='download'>
                    <div class='container m-4 col-lg-7 mx-auto'>
                        <h2 class="section-title text-center">Download <span style={{ color: '#017fff' }}>OrderCash</span></h2>
                        <BrowserView>
                            <div className='col-lg-12 p-4 row mx-auto' >
                                <div class="col-lg-6 mb-5 mb-lg-0">
                                    <a href='https://play.google.com/store/apps/details?id=com.ordercashapp.ordercash'><img alt="" class="mw-100 d-block mx-auto" src={google} /></a>
                                </div>
                                <div class="col-lg-6 mb-5 mb-lg-0">
                                    <a href='#'><img alt="" class="mw-100 d-block mx-auto" src={apple} /></a>
                                </div>
                            </div>
                        </BrowserView>

                        <MobileView>
                            <div className='col-md-12 p-4' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                {(isAndroid) && <div class="col-lg-6 mb-5 mb-lg-0">
                                    <a href='https://play.google.com/store/apps/details?id=com.ordercashapp.ordercash'><img alt="" class="mw-100 d-block mx-auto" src={google} /></a>
                                </div>}
                                {(isIOS) && <div class="col-lg-6 mb-5 mb-lg-0">
                                    <a href='#'><img alt="" class="mw-100 d-block mx-auto" src={apple} /></a>
                                </div>}
                            </div>
                        </MobileView>
                    </div>
                </section>

                <section class="section" id='faq'>
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="text-center mb-5 mb-lg-0 col-lg-5 order-lg-12 wow fadeInUp">
                                <img alt="" class="mw-100" src="img/3-375x753.png" />
                            </div>
                            <div class="col-lg-7">
                                <h2 class="section-title">FAQ</h2>
                                <div class="row mt-5">
                                    <div class="col-md-6 mb-5">
                                        <div class="media">
                                            <i class="mr-3 fa fa-money fa-2x"></i>
                                            <div class="media-body">
                                                <h6>WHAT PROBLEM IS ORDERCASH TRYING TO SOLVE?</h6>
                                                <p>We're making it easier for users to get cash when they need it without visiting the ATM or a money agent.
                                                It's for customers who generally value convenience
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-5">
                                        <div class="media">
                                            <i class="mr-3 fa fa-money fa-2x"></i>
                                            <div class="media-body">
                                                <h6>IS IT EXPENSIVE?</h6>
                                                <p>OrderCash charges similar rates as money agents in addition to a convenience fee.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-5">
                                        <div class="media">
                                            <i class="mr-3 fa fa-money fa-2x"></i>
                                            <div class="media-body">
                                                <h6>HOW LONG WILL IT TAKE FOR ME TO GET MY CASH AFTER ORDERING?</h6>
                                                <p>Barring unforeseen traffic circumstances, delivery will take anywhere from  10  to 40 minutes</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-5">
                                        <div class="media">
                                            <i class="mr-3 fa fa-money fa-2x"></i>
                                            <div class="media-body">
                                                <h6>HOW DO I PLACE AN ORDER?</h6>
                                                <p>This feature allows users to access historical data of all rides, inclusive of rides outside of CoRyde to and from their institution.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="media">
                                            {/* <i class="mr-3 fa fa-signal fa-2x"></i> */}
                                            <div class="media-body">
                                                {/* <h6>Clean design</h6> */}
                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="media">
                                            {/* <i class="mr-3 fa fa-flask fa-2x"></i> */}
                                            <div class="media-body">
                                                {/* <h6>Clean design</h6> */}
                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 mb-lg-0">
                                <div class="col-lg-8 mb-5 ">
                                    <img alt="" class="mw-100 d-block mx-auto" src={phone2} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/*  <!-- Banner --> */}

                <section id="banner" class="banner text-white bgc-primary">
                    <div class="container">
                        {/* <div class="col-md-12 col-lg-6">
                            <h2 class="banner-title text-white">Ready. Set. OrderCash</h2>
                        </div> */}
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h2 class="banner-title text-white">Ready. Set. OrderCash</h2>
                            </div>
                            {/* <div class="col-lg-5 mb-4 mb-lg-0"> (N10,000 is the minimum order)</div>  */}
                            {/* <button class="col-lg-3 btn btn-b-white wow swing" data-toggle="modal" data-target="#request"> 
                                info@ordercashapp.com
                            </button> */}
                        </div>
                    </div>
                </section>


                <section id="subscribe" class="text-center bg-light section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <h2 class="section-title">Contact</h2>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et</p> */}
                            </div>
                        </div>
                        <div class="container mt-5">
                            <p style={{ fontSize: 20 }}>For enquiries please send us an email at <br /><span style={{ color: 'black' }}>info@ordercashapp.com</span> and we will respond to your enquiry as quickly as possible.</p>

                            {/* <div class="row">
                                <div class="col-md-6 offset-md-3">
                                    <form id="mc-form">
                                        <div class="input-group">
                                            <input id="mc-email" type="email" class="form-control b-primary" placeholder="Email address" />
                                            <span class="input-group-btn">
                                                <button class="btn" type="submit"><i class="text-white fa fa-envelope"></i> <span class="hidden-xs">Subscribe</span></button>
                                            </span>
                                        </div>
                                        <p><label for="mc-email" id="mc-notification"></label></p>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </div>

            {/*  <!-- Footer --> */}

            <footer id="footer" class="footer text-center" style={{ backgroundColor: '#017fff' }}>
                <div class="container" >
                    <div class="social">
                        <a href="#" class="fa fa-2x fa-facebook"></a>
                        <a href="#" class="fa fa-2x fa-twitter"></a>
                        <a href="#" class="fa fa-2x fa-pinterest"></a>
                        <a href="#" class="fa fa-2x fa-youtube-play"></a>
                    </div>

                </div>
                <div class="copy text-light">
                    © 2020. OrderCash is a product of GreenLeizer Software Solutions. All rights reserved.
                </div>
            </footer> 
        </div >
    )
}

export default Home