import React, { Component } from 'react'
import { Link } from "react-router-dom"
import Logo from "../assets/icon-android.png"
import './html/css/style.css'



class Navbar extends Component {
    state = {
        shopNamefromURL: '',
        homeURL: '/',
        headerTextWidth: (window.innerWidth <= 375) ? 200 : 500
    }


    componentWillMount = () => {

    }


    render() {

        return (
            <nav className="navbar navbar-expand-md navbar-dark mb-4 bg-custom sticky-top" style={{ backgroundColor:'white', minHeight:110}}>
                <nav class="navbar navbar-expand-lg" data-toggle="affix"  >
                    <div class="container" >
                        <a class="navbar-brand" href="/">
                            <div className="container-fluid text-md-center text-center  d-md-block"
                                style={{
                                    width: '62px', height: '90px',
                                    justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${Logo} )`,
                                    backgroundRepeat: 'no-repeat', backgroundSize: 'contain',
                                }}>
                            </div>
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                            <span class="navbar-toggler-icon"></span>
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent" style={{marginTop:-30}}>
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item">
                                    <a class="nav-link" href="#"><small style={{color:'black'}}>Home</small></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#about"><small style={{color:'black'}}>About</small></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#faq"><small style={{color:'black'}}>FAQ</small></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </nav>
        )
    }
}

export default Navbar
