import React from 'react';
import ReactDOM from 'react-dom'; 
import Home2 from './Home2/Home'  
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
//import 'bootstrap/dist/css/bootstrap.min.css';


const routing = (

    <Router>
        <Switch>
           {/*  <Route exact path="/" component={CorydeHome} />  */} 
           <Route exact path="/" component={Home2} />  
            <Route exact path="/home" component={Home2} />    {/* 
            <Route exact path="/company/signup" component={CompanySignup} />  
            <Route exact path="/schedule/rides" component={Scheduler} />  
            <Route exact path="/welcome" component={Welcome} />   */}
        </Switch>
    </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
